import React from 'react';
import { Link } from 'gatsby';
import styles from './fixedlink.module.scss';
import { BsPlus } from "react-icons/bs"

const FixedLink = () => {
    return (
        <Link to="/ajoutez-une-inspiration" className={styles.fixedLink}>
            <BsPlus />
            Ajoutez une <br/>inspiration
        </Link>
    );
}

export default FixedLink;
